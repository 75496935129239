<template>
    <!-- 添加亲情号 -->
    <div class="main">
        <van-nav-bar title="添加亲情号" left-arrow @click-left="onClickLeft" />
        <!-- 卡号信息 -->
        <div class="head">
            <div class="head_box_info">
                <img class="head_box_info_img" v-if="operatorLogo" :src="operatorLogo" alt="">
                <div class="head_box_info_name">
                    <div class="head_box_info_name_text">
                        本机手表卡号
                    </div>
                    <div class="head_box_info_name_num">{{ sim }}</div>
                </div>
            </div>
        </div>
        <!-- 亲情号码 -->
        <div class="content">
            <div class="content_head">
                <div class="content_head_text">
                    <img src="../../assets/image/yyPlatfrom/kinship.png" alt="">
                    亲情号码
                </div>

                <div class="content_head_right">
                    <div class="content_head_refresh_status">
                        <div v-if="refreshBoll == 1" class="content_head_refresh_status_res refresh_res">亲情号码同步成功</div>
                        <div v-if="refreshBoll == 2" class="content_head_refresh_status_err refresh_res">亲情号码同步失败</div>
                    </div>
                    <div class="content_head_refresh">
                        <img class="completion_img" v-if="!refreshRotate && refreshBoll == 1"
                            src="../../assets/image/yyPlatfrom/refresh_res.png" alt="">
                        <img class="completion_img" v-else-if="!refreshRotate && refreshBoll == 2"
                            src="../../assets/image/yyPlatfrom/refresh_err.png" alt="">
                        <img @click="to_asyncList" v-else :class="refreshRotate ? 'rotate_img' : ''"
                            src="../../assets/image/yyPlatfrom/refresh.png" alt="">
                    </div>
                </div>
            </div>
            <ul class="teg">
                <li @click="tebSwitch('all')" :class="switchIndex == 'all' ? 'teg_active' : ''">全部</li>
                <li @click="tebSwitch(1)" :class="switchIndex == 1 ? 'teg_active' : ''">已添加</li>
                <li @click="tebSwitch(2)" :class="switchIndex == 2 ? 'teg_active' : ''">处理中</li>
                <li @click="tebSwitch(0)" :class="switchIndex == 0 ? 'teg_active' : ''">已删除</li>
            </ul>

            <!-- 列表 -->
            <div class="content_list">
                <div class="content_list_padding_bottom">
                    <template v-for="(item, index) in list" :key="item.id">
                        <div class="content_list_box" v-if="switchIndex == item.status || switchIndex == 'all'">
                            <div class="content_list_box_info">
                                <div class="content_list_box_info_name "
                                    :class="item.status == 0 ? 'color_active' : ''">{{
                                            item.phoneName ? item.phoneName : '未命名'
                                    }}</div>
                                <div class="content_list_box_info_phone "
                                    :class="item.status == 0 ? 'color_active' : ''">{{
                                            item.phone
                                    }}</div>
                            </div>
                            <div class="content_list_box_info_bnt" @click="e => {
                                edit(item, index, e);
                            }">
                                <img src="../../assets/image/yyPlatfrom/set.png" alt="">
                            </div>
                            <div v-if="item.status == 2" class="content_list_box_processed">处理中</div>
                            <div v-else-if="item.status == 0" class="content_list_box_delete">已删除</div>
                            <div v-else-if="item.status == 1" class="content_list_box_success">已添加</div>
                            <div v-else-if="item.status == 2 || item.status == 3"
                                class="content_list_box_processed">处理中</div>
                            <div v-else-if="item.status == 4" class="content_list_box_delete">已取消</div>
                            <div v-else-if="item.status == 5" class="content_list_box_processed">在途单</div>
                            <div v-else class="content_list_box_processed">处理中</div>
                            <div class="content_list_box_set"
                                :class="editItem && editItem.id == item.id ? 'content_list_box_set_show' : ''">
                                <div @click="updateHandle(item)" v-if="item.status != 3 && item.status != 0">
                                    <img src="../../assets/image/yyPlatfrom/rechristen.png" alt="">
                                    修改
                                </div>
                                <div style="color: #FE3A39;" @click="deleteCard(item)" v-if="item.status != 0">
                                    <img src="../../assets/image/yyPlatfrom/delet.png" alt="">
                                    删除
                                </div>
                                <div style="color: #73D13D;" @click="reAdd(item)" v-if="item.status == 0">
                                    <img src="../../assets/image/yyPlatfrom/regain.png" alt="">
                                    恢复
                                </div>
                                <div style="color: rgba(0, 0, 0, 0.4);" @click="clickMask">
                                    <img src="../../assets/image/yyPlatfrom/close.png" alt="">
                                    取消
                                </div>
                            </div>
                        </div>
                        <div class="content_list_box" v-else-if="switchIndex == 2 && item.status == 3">
                            <div class="content_list_box_info">
                                <div class="content_list_box_info_name "
                                    :class="item.status == 0 ? 'color_active' : ''">{{
                                            item.phoneName ? item.phoneName : '未命名'
                                    }}</div>
                                <div class="content_list_box_info_phone "
                                    :class="item.status == 0 ? 'color_active' : ''">{{
                                            item.phone
                                    }}</div>
                            </div>
                            <div class="content_list_box_info_bnt" @click="e => {
                                edit(item, index, e);
                            }">
                                <img src="../../assets/image/yyPlatfrom/set.png" alt="">
                            </div>
                            <div v-if="item.status == 2" class="content_list_box_processed">处理中</div>
                            <div v-else-if="item.status == 0" class="content_list_box_delete">已删除</div>
                            <div v-else-if="item.status == 1" class="content_list_box_success">已添加</div>
                            <div v-else-if="item.status == 2 || item.status == 3" 
                                class="content_list_box_processed">处理中</div>
                            <div v-else-if="item.status == 4" class="content_list_box_delete">已取消</div>
                            <div v-else-if="item.status == 5" class="content_list_box_processed">在途单</div>
                            <div v-else class="content_list_box_processed">处理中</div>
                            <div class="content_list_box_set"
                                :class="editItem && editItem.id == item.id ? 'content_list_box_set_show' : ''">
                                <div @click="updateHandle(item)" v-if="item.status != 3 && item.status != 0">
                                    <img src="../../assets/image/yyPlatfrom/rechristen.png" alt="">
                                    修改
                                </div>
                                <div style="color: #FE3A39;" @click="deleteCard(item)" v-if="item.status != 0">
                                    <img src="../../assets/image/yyPlatfrom/delet.png" alt="">
                                    删除
                                </div>
                                <div style="color: #73D13D;" @click="reAdd(item)" v-if="item.status == 0">
                                    <img src="../../assets/image/yyPlatfrom/regain.png" alt="">
                                    恢复
                                </div>
                                <div style="color: rgba(0, 0, 0, 0.4);" @click="clickMask">
                                    <img src="../../assets/image/yyPlatfrom/close.png" alt="">
                                    取消
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="addPhone">
            <div class="addPhone_bnt" @click="addPhone">
                <van-icon name="plus" style="margin-right: 9px;" />添加亲情号
            </div>

        </div>
        <!-- 修改亲情号码弹窗 -->
        <tip-popup v-if="beforeUpdate.visible" :isWhiteList="true" @onConfirm="closeHandle" @onCancel="onCancel"
            :name="dataForm.phoneName" :mobile="dataForm.phone" :status="dataForm.status"></tip-popup>
    </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance, computed } from "vue";
import { removeStore } from "@/utils/store";
import {
    voiceWhitelist,
    synWhiteList,
    update,
    returnAdd,
    finishWhiteList,
    deleteWhiteList
} from "_API_/api.services";
export default {
    setup() {
        const state = reactive({
            switchIndex: 'all',//标签切换状态
            refreshBoll: null,//1刷新成功  2刷新失败
            refreshRotate: false,//刷新旋转
            sim: "", //sim卡号
            theme: "", //主题名称
            editItem: null, //编辑按钮
            cardId: "", //卡片id
            card: "", //卡号
            page: 1000, //条数
            list: [], //亲情号码列表
            tempList: [], //亲情号码列表 【默认生效中、处理中】
            maxWhitelist: "", //卡号所能添加的最大亲情号码数量
            show: false, //弹框展示
            listIndex: "",
            dataForm: {
                id: 0,
                phone: "",
                cardId: "",
                phoneName: ""
            },
            beforeUpdate: {
                visible: false, //弹框展示
                nickname: "" //昵称
            }, //昵称更新前
            clickBoxTop: 0,
            passagewayType: "", //通道类型
            operator: "",
            apiCode: '',
            isDelete: false, //是否可删除
            isShowListAll: true // 是否展示所有数据
        });
        // 这里的ctx  类似于vue2的ctx
        const { proxy: ctx } = getCurrentInstance();
        const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
        const methods = {
            onClickLeft() {
                router.push({
                    name: "yy-platfrom"
                });
            },
            // 新增亲情号
            addPhone() {
                router.push({
                    name: "yyPlatfrom-familyListAdd",
                });
            },
            // 状态数据筛选 【默认生效中、处理中】
            handleStatus() {
                state.isShowListAll = !state.isShowListAll
                state.list = [...state.tempList]
            },
            // 状态数据筛选 【查看全部】
            handleStatusAll() {
                state.isShowListAll = !state.isShowListAll
                if (state.isShowListAll) {
                    //获取亲情号码列表
                    ctx.getDataList();
                }
            },
            onCancel() {
                state.beforeUpdate.visible = false
            },
            //关闭弹窗组件
            closeHandle(msg) {
                state.beforeUpdate.visible = msg.show_notice;
                state.listIndex = "";
                if (msg.nickname !== "") {
                    state.dataForm.phoneName = msg.nickname;
                    update(state.dataForm).then(({ data: res }) => {
                        if (res.code == 0) {
                            tool.toast(
                                {
                                    msg: "亲情号码修改成功",
                                    duration: 1500,
                                    icon: "success"
                                },
                                () => {
                                    Object.assign(state.dataForm, {
                                        id: 0,
                                        phone: "",
                                        phoneName: ""
                                    });
                                    state.editItem = null;
                                    ctx.getDataList();
                                }
                            );
                            return;
                        }
                        Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
                        tool.toast(
                            {
                                msg: res.msg,
                                duration: 1500
                            },
                            () => { }
                        );
                    });
                }
                if (msg.nickname !== "" && msg.telNum !== "") {
                    Object.assign(state.dataForm, {
                        phone: msg.telNum,
                        phoneName: msg.nickname
                    });

                    if (!state.dataForm.phoneName) {
                        tool.toast(
                            {
                                msg: "请输入名称",
                                duration: 1500
                            },
                            () => { }
                        );
                        return;
                    }
                    if (!state.dataForm.phone && !state.dataForm.id) {
                        tool.toast(
                            {
                                msg: "请输入手机号",
                                duration: 1500
                            },
                            () => { }
                        );
                        return;
                    }
                    if (!/^[0-9]*$/.test(state.dataForm.phone)) {
                        tool.toast(
                            {
                                msg: "手机号格式不对请重新输入",
                                duration: 1500
                            },
                            () => { }
                        );
                        return;
                    }
                    update(state.dataForm).then(({ data: res }) => {
                        if (res.code == 0) {
                            tool.toast(
                                {
                                    msg: !state.dataForm.id ? "亲情号码添加成功" : "亲情号码修改成功",
                                    duration: 1500,
                                    icon: "success"
                                },
                                () => {
                                    Object.assign(state.dataForm, {
                                        id: 0,
                                        phone: "",
                                        phoneName: ""
                                    });
                                    state.editItem = null;
                                    ctx.getDataList();
                                }
                            );
                            return;
                        }
                        Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
                        tool.toast(
                            {
                                msg: res.msg,
                                duration: 1500
                            },
                            () => { }
                        );
                    });
                }
            },
            //修改亲情号码
            updateHandle(row) {
                state.beforeUpdate.visible = true;
                Object.assign(state.dataForm, {
                    id: row.id,
                    cardId: row.cardId,
                    phone: row.phone,
                    phoneName: row.phoneName
                });
                if (row.id) {
                    state.dataForm = JSON.parse(JSON.stringify(row));
                }
            },
            //判断删除
            judgeDelete() {
                if (
                    state.operator !== 1 &&
                    (state.passagewayType == 2 ||
                        state.passagewayType == 20 ||
                        state.passagewayType == 13)
                ) {
                    state.isDelete = true;
                }
            },
            //删除亲情号码
            deleteCard({ id, cardId }) {
                if (!state.isDelete) {
                    return tool.toast({
                        msg: `该资源不允许删除亲情号码`,
                        duration: 1500
                    });
                }
                deleteWhiteList({ id, cardId }).then(({ data: res }) => {
                    if (res.code == 0) {
                        tool.toast(
                            {
                                msg: "删除成功",
                                duration: 1500
                            },
                            () => {
                                Object.assign(state.dataForm, {
                                    id: 0,
                                    phone: "",
                                    phoneName: ""
                                });
                                ctx.getDataList();
                            }
                        );
                        return;
                    }
                    Object.assign(state.dataForm, { id: 0, phone: "", phoneName: "" });
                    let msg = res.msg.replace('白名单', '亲情号码')
                    tool.toast(
                        {
                            msg,
                            duration: 1500
                        },
                        () => { }
                    );
                    ctx.getDataList();
                });
            },
            //重新添加亲情号码
            reAdd({ id }) {
                returnAdd({
                    id
                }).then(({ data: res }) => {
                    if (res.code == 0) {
                        ctx.getDataList();
                        tool.toast(
                            {
                                msg: "重新添加成功",
                                duration: 1500
                            },
                            () => { }
                        );
                        state.editItem = null;
                        return;
                    }
                    let msg = res.msg.replace('白名单', '亲情号码')
                    tool.toast(
                        {
                            msg,
                            duration: 1500
                        },
                        () => { }
                    );
                });
            },
            //取消添加亲情号码
            cancel({ id }) {
                tool.confirm(
                    {
                        title: "提示",
                        msg: "取消以后将不会添加成功，无法拨打此号码，请确认"
                    },
                    () => {
                        finishWhiteList({ id }).then(({ data: res }) => {
                            if (res.code == 0) {
                                ctx.getDataList();
                                tool.toast(
                                    {
                                        msg: "取消添加成功",
                                        duration: 1500
                                    },
                                    () => { }
                                );
                                return;
                            }
                            tool.toast(
                                {
                                    msg: "取消以后将不会添加成功，无法拨打此号码，请确认",
                                    duration: 1500
                                },
                                () => { }
                            );
                        });
                    }
                );
            },
            //获取亲情号码列表
            getDataList() {
                let conf = new FormData();
                conf.append("cardId", state.cardId);
                conf.append("limit", state.page);
                voiceWhitelist(conf).then(({ data: res }) => {
                    if (res.code == 0) {

                        //手机号隐去中间四个字符后的list
                        if (state.isShowListAll) {
                            let Datalist = tool.hideMobile(res.list)

                            let newList = []
                            Datalist.forEach(item => {
                                if (item.status == 1) {
                                    newList.push(item)
                                }
                            });
                            Datalist.forEach(item => {
                                if (item.status == 2) {
                                    newList.push(item)
                                }
                            });
                            Datalist.forEach(item => {
                                if (item.status == 3) {
                                    newList.push(item)
                                }
                            });

                            Datalist.forEach(item => {
                                if (item.status == 0) {
                                    newList.push(item)
                                }
                            });
                            Datalist.forEach(item => {
                                if (item.status != 1 && item.status != 2 && item.status != 3 && item.status != 0) {
                                    newList.push(item)
                                }
                            });

                            state.list = newList
                        }
                    }
                });
            },
            //同步亲情号码
            asyncList() {
                synWhiteList({ cards: state.card }).then(({ data: res }) => {
                    if (res.code == 0) {
                        // tool.toast({
                        //     msg: `亲情号码同步成功`,
                        //     duration: 1500
                        // });
                        ctx.getDataList();
                        return;
                    }
                    let msg = res.msg.replace('白名单','亲情号码')
                    tool.toast(
                        {
                            msg,
                            duration: 1500
                        },
                        () => { }
                    );
                });
            },
            //顶部手动点击同步亲情号码
            to_asyncList() {
                state.refreshBoll = null
                state.refreshRotate = true
                setTimeout(() => {
                    synWhiteList({ cards: state.card }).then(({ data: res }) => {
                        if (res.code == 0) {
                            state.refreshBoll = 1
                            state.refreshRotate = false
                            setTimeout(() => {
                                state.refreshBoll = null
                            }, 2000);
                            return;
                        }
                        state.refreshBoll = 2
                        state.refreshRotate = false
                        setTimeout(() => {
                            state.refreshBoll = null
                        }, 2000);
                    });
                }, 500);

            },
            tebSwitch(val) {//切换teg 
                state.switchIndex = val
            },
            //状态列表
            parseStatus(val) {
                switch (val) {
                    case 0:
                        return "已删除";
                    case 1:
                        return "已添加";
                    case 2:
                        return "处理中";
                    case 3:
                        return ['处理中', '#FAAD14']
                    case 4:
                        return "已取消";
                    case 5:
                        return '在途单'
                    default:
                        return "处理中";
                }
            },
            //添加新号码
            addNum() {
                if (state.list.length > state.maxWhitelist - 1) {
                    tool.toast(
                        {
                            msg: `最多只能添加${state.maxWhitelist}个亲情号码`,
                            duration: 1500
                        },
                        () => { }
                    );
                    return;
                }
                router.push({
                    name: "personal-whiteListAdd",
                    query: {
                        length: state.list.length
                    }
                });
            },
            // 点击编辑
            edit(item, index, e) {
                // let scrollTop = document.querySelector(".desc_detail_box").scrollTop;
                // let offTop = document.querySelectorAll(".desc_detail")[index].offsetTop;
                // let cH =
                //     document.querySelectorAll(".desc_detail")[index].clientHeight * 0.6;
                // state.clickBoxTop = offTop + cH - scrollTop;
                state.editItem = state.editItem && state.editItem.id == item.id ? null : item;
                // debugger
            },
            clickMask() {
                state.editItem = null;
                // document.querySelector(".listWrap").style.paddingBottom = "";
            }
        };
        const operatorLogo = computed(() => {

            switch (state.operator) {
                case 1:
                    return require('../../assets/image/yyPlatfrom/ChinaTelecom.png')
                case 2:
                    return require('../../assets/image/yyPlatfrom/ChinaMobile.png')
                case 3:
                    return require('../../assets/image/yyPlatfrom/ChinaUnicom.png')
                case 4:
                    return require('../../assets/image/yyPlatfrom/operator4.png')
                default:
                    return require('../../assets/image/yyPlatfrom/operator4.png')
            }
        });
        onMounted(() => {
            //获取主题名称
            state.theme = store.state.common.theme;
            //获取用户信息
            const info = store.state.user.userInfo;
            //获取sim卡号
            state.sim = info.sim || "";
            if (!state.sim) {
                removeStore({ name: "loginAccessToken", type: "session" });
                router.push({
                    name: "card-query"
                });
                return;
            }
            state.apiCode = info.apiCode
            Object.assign(state, {
                cardId: info.cardId || "", //获取卡id
                card: info.virtualId || info.iccid, //获取卡号
                maxWhitelist: info.maxWhitelist, //卡号所能添加的最大亲情号码数量
                passagewayType: info.passagewayType, //通道类型
                operator: info.operator
            });
            //获取亲情号码列表
            ctx.getDataList();
            //判断亲情号码是否可删除
            ctx.judgeDelete();
            //同步亲情号码 非全国联通
            if (state.apiCode != 3001) {
                ctx.asyncList();
            }
        });
        return {
            ...toRefs(state),
            tool,
            ...methods,
            operatorLogo
        };
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixin.scss";
@import "@/style/theme/index";

.main {
    width: 100%;

    .head {
        width: 100%;
        height: 208px;
        background: linear-gradient(90deg, #FF584B 0%, #FE1E14 100%);
        overflow: hidden;

        .head_box_info {
            margin-top: 40px;
            margin-left: 32px;
            display: flex;

            .head_box_info_img {
                width: 96px;
                height: 96px;
                border-radius: 50%;
                margin-right: 24px;
            }

            .head_box_info_name {
                .head_box_info_name_text {
                    font-size: 28px;
                    line-height: 40px;
                    color: rgba(255, 255, 255, 0.6);

                }

                .head_box_info_name_num {
                    font-size: 40px;
                    line-height: 56px;
                    color: #FFFFFF;
                    font-weight: 700;
                }
            }
        }
    }

    .content {
        width: 100%;
        height: calc(100vh - 176px);
        overflow: hidden;
        background-color: #FFFFFF;
        border-radius: 32px 32px 0px 0px;
        margin-top: -32px;

        // height: 100%;
        // padding: 0px 32px;

        .content_head {
            width: 92%;
            margin: auto;
            height: 112px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content_head_text {
                font-size: 32px;
                line-height: 48px;
                color: rgba(0, 0, 0, 0.8);
                display: flex;
                align-items: center;

                img {
                    width: 48px;
                    height: 48px;
                    margin-right: 16px;
                }
            }

            .content_head_right {
                display: flex;
            }

            .content_head_refresh_status {
                width: 216px;
                height: 48px;
                border-radius: 8px;
                overflow: hidden;
                position: relative;

                .content_head_refresh_status_res {
                    width: 216px;
                    height: 48px;
                    font-size: 24px;
                    line-height: 48px;
                    border-radius: 8px;
                    text-align: center;
                    background: #73D13D;
                    color: #FFFFFF;
                    position: absolute;
                    right: -220px;
                }

                .content_head_refresh_status_err {
                    width: 216px;
                    height: 48px;
                    font-size: 24px;
                    line-height: 48px;
                    border-radius: 8px;
                    text-align: center;
                    background: #FE3A39;
                    color: #FFFFFF;
                    position: absolute;
                    right: -220px;
                }

                .refresh_res {

                    animation: refresh_res 0.5s;
                    right: 0px;
                }

                @keyframes refresh_res {
                    0% {
                        right: -220px;
                    }

                    100% {
                        right: 0px;
                    }
                }
            }

            .content_head_refresh {
                width: 82px;
                border-left: solid rgba(0, 0, 0, 0.1) 2px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                img {
                    width: 48px;
                    height: 48px;
                }

                .rotate_img {
                    animation: fadenum 1s linear infinite;
                }

                .completion_img {
                    animation: rotatenum 0.2s linear;
                }

                @keyframes fadenum {
                    100% {
                        transform: rotate(360deg);
                    }

                }

                @keyframes rotatenum {
                    100% {
                        transform: rotate(-180deg);
                    }

                }
            }
        }

        .teg {
            width: 92%;
            margin: auto;
            height: 80px;
            display: flex;
            align-items: center;

            li {
                height: 48px;
                padding: 0px 16px;
                margin-right: 16px;
                line-height: 48px;
                border-radius: 8px;
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
                font-size: 24px;
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 9px;
            }

            .teg_active {
                background: rgba(0, 0, 0, 0.8);
                color: #FFFFFF;
            }
        }

        .content_list {
            padding: 32px 32px 0px 32px;
            height: calc(100% - 200px);
            overflow-y: auto;

            .content_list_padding_bottom {
                padding-bottom: 172px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .content_list_box {
                width: 328px;
                height: 152px;
                margin-bottom: 30px;
                border: 2px solid #E6E6E6;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                overflow: hidden;

                .content_list_box_info {
                    width: 200px;
                    margin-left: 24px;

                    .content_list_box_info_name {
                        font-size: 32px;
                        line-height: 44px;
                        color: rgba(0, 0, 0, 0.8);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; //控制显示几行
                        -webkit-box-orient: vertical; //webbox方向
                    }

                    .content_list_box_info_phone {
                        font-size: 24px;
                        line-height: 40px;
                        color: rgba(0, 0, 0, 0.4);
                    }
                }

                .content_list_box_info_bnt {
                    height: 152px;
                    display: flex;
                    align-items: center;
                    padding-right: 24px;

                    img {
                        width: 48px;
                        height: 48px;
                    }
                }

                .content_list_box_processed {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    line-height: 48px;
                    background: #FAAD14;
                    border-radius: 0px 16px;
                    padding: 0px 16px;
                    font-size: 24px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .content_list_box_delete {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    line-height: 48px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 0px 16px;
                    padding: 0px 16px;
                    font-size: 24px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .content_list_box_success {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    line-height: 48px;
                    background: #73D13D;
                    border-radius: 0px 16px;
                    padding: 0px 16px;
                    font-size: 24px;
                    text-align: center;
                    color: #FFFFFF;
                }

                .content_list_box_set {
                    width: 328px;
                    height: 152px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    right: -330px;
                    background: #F7F7F7;
                    font-size: 24px;
                    border-radius: 16px;

                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            width: 36px;
                            height: 36px;
                            margin-bottom: 16px;
                        }
                    }
                }

                .content_list_box_set_show {
                    animation: leftToRight 0.5s;
                    right: 0px;
                }

                @keyframes leftToRight {
                    0% {
                        right: -330px;
                    }

                    50% {
                        right: -160px;
                    }

                    100% {
                        right: 0px;
                    }
                }
            }

            .color_active {
                color: rgba(0, 0, 0, 0.2) !important;
            }
        }
    }

    .addPhone {
        position: fixed;
        width: 100%;
        height: 172px;
        left: 0px;
        bottom: 0px;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;

        .addPhone_bnt {
            width: 686px;
            height: 100px;
            line-height: 172px;
            background: linear-gradient(90deg, #FF584B 0%, #FE1E14 100%);
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            color: #FFFFFF;

        }
    }

}

.van-nav-bar {
    background: linear-gradient(90deg, #FF584B 0%, #FE1E14 100%);

    /deep/.van-nav-bar__left {
        .van-icon {
            color: #ffffff;
            font-size: 48px;
        }
    }

    /deep/ .van-nav-bar__title {
        font: {
            size: 30px;
            weight: 500;
        }

        color: #ffffff;
    }
}

.van-hairline--bottom::after {
    border: none;
}
</style>
